<!-- 视频监控-视频监控 -->
<template>
    <div style="width:100%;height:100%">
        <!-- <iframe style="width:100%;height:100%" src="https://www.hik-cloud.com/safe-center/index.html#/login/retail?type=true&direction=%2Fvideo%2Fscene" frameborder="0"></iframe> -->
        <div class="linkTo">
            正在跳转至新页面，请稍后...
        </div>
    </div>
</template>
<script>
    import {
        productionInforTeamSelect,
        productionInforEquipmentOutputByDayQuery,

    } from "@/api/api_productionManagement";
    export default {
        data() {
            return {

            }
        },
        
        created (){
            this.open(); 
        },
       
        methods: {
            open(){
            window.open('https://www.hik-cloud.com/safe-center/index.html#/login/retail?type=true&direction=%2Fvideo%2Fscene')
        },
        }
    }
</script>

<style scoped>
   
</style>
<style lang="less" scoped>
    .linkTo{
        width: 100%;
        height: 100%;
        font-size: 30px;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
        align-content: center;
    }
</style>